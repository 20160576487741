<template>
  <div class="header-wrap relative">
    <div class="wrap-ico">
      <span class="title-small">要找{{defaultRealHouseName}} 还是{{companyConfig.firstName}}全</span>
      <h1 class="title-big bold">开始寻找属于你的家</h1>
    </div>
    <div class="search-box-wrapper">
      <div class="menu relative ">
        <ul class="row">
          <li v-for="(menu,index) in menuNavs" :key="menu.key" :class="[menu.key === current ? 'white':'colb3']" class="size16 bold cursor" @click="handleMenu(index)">{{menu.title}}</li>
        </ul>
        <i ref="triangle"></i>
      </div>
      <!-- stop:解决点击input 提示框消失   -->
      <div class="serach relative" @click.stop="">
        <div class="whiteBG row radius5 ">
          <div class="input-wrap ">
            <input v-model="estateName" :placeholder="placeholder" @focus="updateSearchTip(true)" @keyup.enter="handleSubmit" />
          </div>
          <router-link to="/map" target="_blank" class="map-location row c_center col66" :style="{opacity:current === 2 ? '0':'1' }">
            <i class="el-icon-map-location size20 "></i>
            <span class="size16 bold pl10">地图</span>
          </router-link>
          <a @click="handleSubmit" href="javascript:;" class="sear-btn greenBG white">
            <i class="el-icon-search size20"></i>
          </a>
        </div>
        <div class="sug-tips" @click.stop="">
          <search-tip :estateName="estateName" :tradeType="tradeType" :showSearchTip="showSearchTip" />
        </div>
      </div>
    </div>
    <div style="height: 60px;"></div>
    <!-- 轮播 -->
    <div class="header-bottom white row s_center">
      <i class="el-icon-s-opportunity "></i>
      <el-carousel style="width: 200px;margin-left:5px;margin-top:5px" height="25px" direction="vertical" indicator-position="none">
        <el-carousel-item v-for="item in houseNums" :key="item">
          <span class="medium white">{{ item }}</span>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import SearchTip from "@/components/search-tip.vue";
import Constant from "../../../common/Constant"
import { mapState } from "vuex";
export default {
  name: "HeaderWrap",
  props: {
    houseNums: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      estateName: "",
      tradeType: 2,
      current: 1,
      placeholder: "请输入商圈或小区名开始找房",
      menuNavs: [
        {
          title: "找二手房",
          placeholder: "请输入商圈或小区名开始找房",
          tradeType: 2,
          key: 1
        },
        {
          title: "找新房",
          placeholder: "请输入楼盘名开始找房",
          tradeType: 3,
          key: 2
        },
        {
          title: "找租房",
          placeholder: "请输入商圈或小区名开始找房",
          tradeType: 1,
          key: 3
        },
        {
          title: "找小区",
          placeholder: "请输入小区名开始找小区",
          tradeType: 4,
          key: 4
        }
      ]
    };
  },
  computed: {
    ...mapState(["showSearchTip","defaultRealHouseName","companyConfig"]),
  },
  components: {
    SearchTip
  },
  methods: {
    handleMenu(index) {
      const { placeholder, key, tradeType } = this.menuNavs[index];
      this.placeholder = placeholder;
      this.tradeType = tradeType;
      this.current = key;
      let left = 23; //初始位置
      left += 120 * (key - 1);
      this.$refs["triangle"].style.left = left + "px";
    },
    updateSearchTip(isShow) {
      this.$store.commit("updateState", {
        data: isShow,
        key: "showSearchTip"
      });
    },
    handleSubmit() {
      this.updateSearchTip(false);
      if (this.tradeType === 3) {
        this.$router.push({
          path: "/loupan",
          query: { name: this.estateName }
        });
        return;
      }
      this.$router.push({
        path: `/house/${this.tradeType}`,
        query: { estateName: this.estateName }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.header-wrap {
  .wrap-ico {
    margin-top: 116px;
    text-align: left;
    color: #fff;
    font-size: 66px;
    line-height: 68px;
    letter-spacing: -2.4px;
    .title-small {
      font-size: 28px;
      line-height: 28px;
      height: 28px;
      font-weight: 400;
      margin-bottom: 18px;
    }
  }
  .search-box-wrapper {
    width: 590px;
    margin-top: 36px;
    .menu {
      height: 41px;
      li {
        line-height: 25px;
        height: 25px;
        width: 124px;
        text-align: left;
        &:hover {
          color: #fff;
        }
      }
      i {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        width: 0;
        height: 0;
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 31px;
        left: 23px;
        border-bottom: 10px solid #fff;
        z-index: 9;
      }
    }
  }
  .serach {
    .input-wrap {
      width: 380px;
      height: 50px;
      padding-left: 20px;
      input {
        height: 100%;
        border: none;
        outline: none;
        width: 100%;
      }
    }
    .map-location {
      width: 114px;
      &:hover {
        color: #00ae66 !important;
      }
    }
    .sear-btn {
      width: 76px;
      height: 100%;
      line-height: 50px;
      text-align: center;
    }
    .sug-tips {
      position: absolute;
      top: 10px;
      left: 0;
      width: 514px;
      z-index: 100;
    }
  }
  .header-bottom {
    height: 20px;
    line-height: 20px;
    overflow: hidden;
  }
}
</style>


