<template>
    <div class="mb80">
        <div class="mt80">
            <h1 class="name bold black">{{menu.title}}</h1>
            <div class="row sb_center sub-title size16">
                <span>{{menu.subTitle}}</span>
                <router-link class="green bold" :to="menu.page">更多{{menu.rightTitle}}</router-link>
            </div>
        </div>
        <div v-if="menu.house && menu.house.length">
            <ul class="row mt26 wrap sale-house" v-if="menu.tradeType == 1 || menu.tradeType == 2">
                <house-item  v-for="house in menu.house"  :key="house.guid" :houseItem="house" :trade-type="menu.tradeType"/>
            </ul>
            <ul class="row mt26" v-else-if="menu.tradeType == 4">
                <resblock-item  v-for="house in menu.house"  :houseItem="house" :key="house.guid" />
            </ul>
            <ul class="row mt26" v-else>
                <estate-item  v-for="house in menu.house" :houseItem="house"  :key="house.guid" />
            </ul>
        </div>
        <div v-else>
            <el-empty :description="menu.start ? getEmptyText:'房源加载中···' "></el-empty>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import HouseItem from "./house-item.vue"
import ResblockItem from "./resblock-item.vue"
import EstateItem from "./estate-item.vue"

export default {
    props:{
        menu: {
            type: Object,
            required: true
        }
    },
    components:{
        HouseItem,
        ResblockItem,
        EstateItem
    },
    computed: {
        ...mapState(["location"]),
        getEmptyText() {
            switch(this.menu.tradeType) {
                case 1:
                    return '当前城市暂无推荐租房,请切换城市'
                case 2:
                    return '当前城市暂无推荐二手房,请切换城市'
                case 3:
                    return '当前城市暂无推荐新房,请切换城市'
                default :
                    return "当前城市暂无推荐小区,请切换城市"
            }
        }
    },
}
</script>
<style lang="less" scoped>

 .mb80 {
    margin-bottom: 80px
  }
  .mt80 {
    margin-top: 100px
  }
  .mt26 {
    margin-top: 26px
  }
  .name {
    font-size: 36px;
    line-height: 50px;
  }
  .sub-title {
    color: #9399a5;
    line-height: 22px;
  }


</style>


