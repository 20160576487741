<template>
  <li class="container" :title="houseItem.name">
    <router-link :to="{
            name: 'new-estate-detail',
            query: {
                guid: houseItem.guid,
            }}" target="_blank">
      <img v-if="houseItem.coverImage" class="cover" :src="houseItem.coverImage" alt="" />
      <img v-else :src="defaultCover" class="cover" alt="" />
      <!-- <div class="title-wrap"> -->
      <div class="title size24 white bold">
        <p class="block ellipsis" style="width:100%">{{houseItem.name}}</p>
        <span class="size14" v-if="houseItem.averagePrice"> {{houseItem.averagePrice}}元/平</span>
      </div>
      <!-- </div> -->
    </router-link>
  </li>
</template>

<script>
import Constant from "@/common/Constant";
export default {
  props: {
    houseItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      defaultCover: Constant.DEFAULT_HOUSE
    };
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 364px;
  height: 272px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  &:not(:last-child) {
    margin-right: 29px;
  }

  .cover {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: 0.7s ease-in-out;
    transform: scale(1);
    &:hover {
      transform: scale(1.2);
    }
  }
  .title-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .title {
    position: absolute;
    bottom: 25px;
    width: 242px;
    height: 54px;
    left: 50%;
    top: 50%;
    margin-left: -121px;
    text-align: center;
    margin-top: -27px;
  }
}
</style>

