<template>
  <div v-if="isShowDownload" class="dowmload-wrapper">
    <div class="inner row c_center">
      <img class="footer-phone" src="@/assets/imgs/home_footer_phone.png" alt="" />
      <div class="text-wrapper">
        <h2 class="bold size20">扫码下载{{companyConfig.firstName + companyConfig.lastName}}APP</h2>
        <span class="size13 whiteGray">海量真房源</span>
      </div>
      <div class="relative">
        <img class="scan-download" :src="downloadUrl" alt="" />
        <i class="el-icon-close size22 gray close-dowmload-wrapper cursor" @click='$emit("close-footer-download",false)'></i>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex"

export default {
  name: "footer-download",
  props: {
    isShowDownload: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(["companyConfig"]),
    downloadUrl() {
        return this.companyConfig.downloadUrl
    }
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style scoped lang="less">
.dowmload-wrapper {
  position: fixed;
  bottom: 0px;
  background-color: white;
  width: 100%;
  transition: all 300ms;
  z-index: 100;
  .inner {
    width: 1200px;
    margin: auto;
    height: 130px;
    box-sizing: border-box;
    .footer-phone {
      position: relative;
      bottom: 20px;
    }
    .text-wrapper {
      line-height: 40px;
      margin: 30px 50px;
    }
    .scan-download {
      width: 83px;
      height: 83px;
    }
    .close-dowmload-wrapper {
      position: absolute;
      right: -45px;
      top: -2px;
    }
  }
}
</style>